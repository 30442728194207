<template lang="pug">
  v-card
    .reports-table__row.reports-table__row--body
      .reports-table__column {{ item.date | date }}
      .reports-table__column(
        :style="{ color: statuses[item.status].color }")
        | {{ statuses[item.status].name | translate }}
      .reports-table__column {{ item.ration.name }}
      .reports-table__column
        v-dropdown(width-auto)
          .text-underline {{ item.recipe_name }}
          template(slot="content")
            v-card.overflow-wrap-normal(
              white
              secondary
              elevated)
              table.table.table--no-background.table--small.table--cell-overflow-hidden(style="min-width: 300px")
                thead.table__header
                  tr.table__row.table__row--header
                    th.table__cell {{ 'base.#' | translate }}
                    th.table__cell {{ 'base.ingredient' | translate }}
                    th.table__cell {{ 'base.percent_of_input' | translate }}
                    //- th.table__cell {{ 'base.premix_base' | translate }}
                    //- th.table__cell {{ 'base.composite_premixes' | translate }}
                tbody.table__body
                  tr.table__row.table__row--body.table__row--no-hover(
                    v-for="(ingredient, index) in item.recipe.proportions"
                    :key="index")
                    td.table__cell {{ index + 1 }}
                    td.table__cell(
                      :title="ingredient.ingredient_name")
                      | {{ ingredient.ingredient_name }}
                    td.table__cell {{ ingredient.rate }}%
                    //- td.table__cell
                      v-chevron(v-if="ingredient.need_premix")
                    //- td.table__cell
                      v-chevron(v-if="ingredient.need_oil")

      .reports-table__column {{ item.plan_weight }}
      .reports-table__column {{ item.fact_weight }}
      .reports-table__column {{ calcTotalValue(item.batches, 'unload_weight') }}
      .reports-table__column {{ item | notFinishedKg | abs | toFixed }} / {{ item | notFinishedPercent | abs | toFixed }}%
      .reports-table__column {{ item.started_at }}
      .reports-table__column {{ calcTotalValue(item.batches, 'load_time') | sectotime }}
      .chevron-box.chevron-box--position-right
        v-btn(
          icon
          small
          :disabled="item.status !== 'empty' && !item.batches.length"
          @click="toggled = !toggled")
          v-chevron(:value="toggled")

    template(v-if="toggled")
      v-divider(secondary)
      reports-load-table(
        :items="item.batches")

</template>

<script>
import { PLANNING_STATUSES } from '@/util/consts.js'

import ReportsLoadTable from './ReportsLoadTable'

const reducer = (accumulator, value) => Math.floor((accumulator + value) * 100) / 100

export default {
  name: 'ReportsTableRow',

  components: {
    ReportsLoadTable
  },

  props: {
    item: Object
  },

  mounted () {
    // prepear premix structure
    this.item.recipe.proportions = this.item.recipe.proportions.map(item => {
      const premix = (this.item.premix || [])
        .find(el => el.ingredient_id === item.ingredient_id)
      return {
        ...item,
        need_premix: premix ? !!premix.need_premix : false,
        need_oil: premix ? !!premix.need_oil : false
      }
    })
  },

  filters: {
    notFinishedKg (item) {
      const diff = item.plan_weight - item.fact_weight
      return diff > 0 ? diff : 0
    },

    notFinishedPercent (item) {
      const diff = item.plan_weight - item.fact_weight
      return diff > 0
        ? Math.floor((item.plan_weight - item.fact_weight) / item.plan_weight * 100)
        : 0
    },

    abs (value) {
      return Math.abs(value)
    }
  },

  data: () => ({
    statuses: PLANNING_STATUSES,
    toggled: false
  }),

  methods: {
    calcTotalValue (items, field) {
      return items
        .map(item => +item[field])
        .reduce(reducer, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
