<template lang="pug" functional>
  div
    v-card.reports-skeleton(v-for="i in props.count" :key="i")
      .reports-table__row.reports-table__row--body
        .reports-table__column
          .text-skeleton
        .reports-table__column
          .text-skeleton
        .reports-table__column
          .text-skeleton
        .reports-table__column
          .text-skeleton
        .reports-table__column
          .text-skeleton

</template>

<script>
export default {
  name: 'ReportsTableRowSkeleton',

  props: {
    count: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_mixins.scss';
  @import '@/sass/abstracts/_variables.scss';

  .reports-skeleton {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .text-skeleton {
    height: 2rem;
    width: 60%;
    border-radius: .8rem;

    @include skeleton;
  }
</style>
