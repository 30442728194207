<template lang="pug">
  .reports-load-table-row
    .reports-load-table__row.reports-load-table__row--body.table__row--body.cursor-pointer(
        @click="showBatchInfo = !showBatchInfo"
      )
        .reports-load-table__column.batch(
          :style="{color: statuses[item.status].color}")
          | {{ statuses[item.status].name | translate }}
        .reports-load-table__column.batch {{ index }}
        .reports-load-table__column.batch {{ item.recipe_name }}
        .reports-load-table__column.batch {{ item.plan_weight }}
        .reports-load-table__column.batch {{ item.fact_weight }}
        .reports-load-table__column.batch {{ item.unload_weight }}
        .reports-load-table__column.batch {{ item | notFinishedKg | abs | toFixed }} / {{ item | notFinishedPercent | abs | toFixed }}%
        .reports-load-table__column.batch {{ item.time }}
        .reports-load-table__column.batch.padding_end {{ item.load_time | sectotime }}

    v-dialog(
    max-width="800"
    v-model="showBatchInfo")
      batch-info-modal(
        :item="item"
        @close="showBatchInfo = false")

</template>

<script>
import { PLANNING_STATUSES } from '@/util/consts.js'
import { TABLE_LIST } from '@/assets/data/reports.js'
import BatchInfoModal from '../modals/BatchInfoModal.vue'

export default {
  name: 'ReportsLoadTableRow',
  components: {
    BatchInfoModal
  },
  props: {
    index: Number,
    item: Object
  },

  filters: {
    notFinishedKg (item) {
      return item.plan_weight - item.fact_weight
    },

    notFinishedPercent (item) {
      return Math.floor((item.plan_weight - item.fact_weight) / item.plan_weight * 100)
    },

    abs (value) {
      return Math.abs(value)
    }
  },

  data: () => ({
    statuses: PLANNING_STATUSES,
    list: TABLE_LIST,
    showBatchInfo: false
  })
}
</script>

<style lang="scss" scoped>
  .padding_end {
    padding-right: 30px !important;
  }
</style>
