<template lang="pug">
  .reports-load-table
    .reports-load-table__row.reports-load-table__row--header
      .reports-table__column.batch {{ 'base.status' | translate }}
      .reports-table__column.batch #
      .reports-table__column.batch {{ 'base.recipe' | translate }}
      .reports-table__column.batch {{ 'base.plan' | translate }} [{{ 'units.kg' | translate }}]
      .reports-table__column.batch {{ 'base.fact' | translate }} [{{ 'units.kg' | translate }}]
      .reports-table__column.batch {{ 'base.unloaded' | translate }} [{{ 'units.kg' | translate }}]
      .reports-table__column.batch {{ 'base.deviation' | translate }} [{{ 'units.kg' | translate }}/%]
      .reports-table__column.batch {{ 'base.start_time' | translate }}
      .reports-table__column.batch {{ 'base.duration' | translate }} [+/-]

    .reports-load-table__body
      reports-load-table-row(
        v-for="(item, index) in items"
        :key="item.id"
        :item="item"
        :index="index + 1")

</template>

<script>
  import ReportsLoadTableRow from './ReportsLoadTableRow'

  export default {
    name: 'ReportsLoadTable',

    components: {
      ReportsLoadTableRow
    },

    props: {
      items: Array
    }
  }
</script>

<style lang="scss">
  @import '@/sass/abstracts/_variables';

  .reports-load-table {

    padding-left: 85px;
    background: #F1F1F1;

    &__column {
      font-size: 1.3rem;
    }

    &__row {
      position: relative;
      padding: 1rem 1.5rem;
      display: flex;
      align-items: center;
      background: white;

      &--header {
        padding: 1.5rem;
        color: $color-gray;
      }

      &--body {
        border-top: .1rem solid $color-gray-light-2;

        .reports-load-table {
          &__column {
            padding-right: 1rem;
            // text-overflow: ellipsis;
            // overflow: hidden;
            // white-space: nowrap;

            &:nth-child(1) {
              flex: 1 1 11%;
            }

            &:nth-child(2) {
              flex: 1 1 12.85%;
            }

            &:nth-child(3) {
              flex: 1 1 12%;
            }

            &:nth-child(4) {
              flex: 1 1 14%;
            }

            &:nth-child(5) {
              flex: 1 1 9%;
            }

            &:nth-child(6) {
              flex: 1 1 8.5%;
            }

            &:nth-child(7) {
              flex: 1 1 14%;
            }

            &:nth-child(8) {
              flex: 1 1 11%;
            }

            &:nth-child(9) {
              flex: 1 1 7%;
            }

            &:nth-child(10) {
              flex: 1 1 10%;
            }

            // &:nth-child(1) {
            //   flex: 1 1 12%;
            // }

            // &:nth-child(2) {
            //   flex: 1 1 13%;
            // }

            // &:nth-child(3) {
            //   flex: 1 1 15%;
            // }

            // &:nth-child(4) {
            //   flex: 1 1 40%;
            // }

            // &:nth-child(5) {
            //   flex: 1 1 20%;
            // }

            // &:nth-child(6) {
            //   flex: 1 1 20%;
            //   padding-right: 0;
            // }
          }
        }

        .reports-load-table {
          &__column {
            &.batch {
              flex: 1 1 11%;
              &:nth-child(2) {
                flex: 1 1 5%;
              }
              &:nth-child(3) {
                flex: 1 1 15%;
              }
            }
          }
        }
      }
    }
  }
</style>
